<template>
  <div class="select-input" @click="toggleOptions" @focusout="close" :class="{ opened: opened, reversed: reverseOptions }" tabindex="0">
    <div class="select-input__label">{{selected !== -1 ? (labelKey ? options[selected][labelKey] : options[selected]) : (emptyVal || 'Select')}}</div>
    <i class="fas" :class="{ 'fa-chevron-down': !opened, 'fa-chevron-up': opened }"/>
    <input type="hidden" :value="selected !== -1 ? (valueKey ? options[selected][valueKey] : options[selected]) : ''"/>
    <div class="select-input__options">
      <div v-for="(option, index) in options" :key="index" class="select-input__option" @click="selectItem(index)">
        {{labelKey ? option[labelKey] : option}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Select',
  props: [
    'options',
    'labelKey',
    'valueKey',
    'emptyVal',
    'disabled',
    'reverseOptions',
    'value'
  ],
  data () {
    return {
      opened: false
    }
  },
  mounted () {
  },
  computed: {
    selected () {
      let index = -1
      this.options.forEach((i, e) => {
        if ((!this.valueKey && i === this.value) ||
          (this.valueKey && this.value && i[this.valueKey] === this.value[this.valueKey]) ||
          (this.valueKey && this.value && i[this.valueKey] === this.value)) {
          index = e
        }
      })

      return index
    }
  },
  methods: {
    toggleOptions () {
      if (!this.disabled) {
        this.opened = !this.opened
      }
    },
    close () {
      this.opened = false
    },
    selectItem (index) {
      this.$emit('select', this.options[index])
    }
  }
}
</script>

<style scoped lang="scss">
</style>
