<template>
  <div class="business-contact">
    <div v-if="hasTitle" class="flex align-center justify-between mg-t-20 pd-t-10 mg-b-10">
      <strong>{{title}}</strong>
      <i v-if="!readOnly" class="fas fa-trash-alt pointer font-18 red" @click="onDelete"/>
    </div>
    <div class="flex align-start justify-between mg-b-5 mg-t-10">
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.first_name')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.first_name}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.first_name')}}</label>
          <input  :value="modelValue.first_name" @change="onChange('first_name', $event.target.value)" type="text" class="form-control" required/>
        </div>
      </div>
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.last_name')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.last_name}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.last_name')}}</label>
          <input :value="modelValue.last_name" @change="onChange('last_name', $event.target.value)" type="text" class="form-control" required/>
        </div>
      </div>
    </div>
    <div class="flex align-start justify-between mg-b-5 mg-t-10">
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.job_title')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.job_title}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.job_title')}}</label>
          <input :value="modelValue.job_title" @change="onChange('job_title', $event.target.value)" type="text" class="form-control" required/>
        </div>
      </div>
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.email_field')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.email}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.email_field')}}</label>
          <input :value="modelValue.email" @change="onChange('email', $event.target.value)" type="email" class="form-control" required/>
        </div>
      </div>
    </div>
    <div class="flex align-start justify-between mg-b-5 mg-t-10">
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.phone')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.phone}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.phone')}}</label>
          <input :value="modelValue.phone" @change="onChange('phone', $event.target.value)" type="text" class="form-control" placeholder="12345678" required/>
        </div>
      </div>
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.dob')}}</div>
          <div class="text-dark mg-b-5">{{$filters.formatDate(modelValue.dob)}}</div>
        </div>
        <div v-else>
          <label>{{$t('store.dob')}}</label>
          <div class="form-element with-icon mg-t-5">
            <datepicker :model-value="modelValue.dob" @update:modelValue="(val) => onChange('dob', val)" :placeholder="'YYYY-MM-DD'" starting-view="year" format="YYYY-MM-DD" class="form-control" required/>
            <img src="/images/icons/calendar.png" class="form-icon"/>
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-start justify-between mg-b-5 mg-t-10">
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.hk_id')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.hk_id}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.hk_id')}}</label>
          <input :value="modelValue.hk_id" @change="onChange('hk_id', $event.target.value)" type="text" class="form-control"  placeholder="A1234567" minlength="8" maxlength="9" required/>
        </div>
      </div>
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.country')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.country}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.country')}}</label>
          <input :value="modelValue.country" @change="onChange('country', $event.target.value)" type="text" class="form-control"  placeholder="HKSAR" required/>
        </div>
      </div>
    </div>
    <div class="flex align-start justify-between mg-b-5 mg-t-10">
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.address_l1')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.address_line1}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.address_l1')}}</label>
          <input :value="modelValue.address_line1" @change="onChange('address_line1', $event.target.value)" type="text" class="form-control" required/>
        </div>
      </div>
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.address_l2')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.address_line2}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.address_l2')}}</label>
          <input :value="modelValue.address_line2" @change="onChange('address_line2', $event.target.value)" type="text" class="form-control" required/>
        </div>
      </div>
    </div>
    <div class="flex align-start justify-between mg-b-5 mg-t-10">
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.area')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.region?.name}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.area')}}</label>
          <select-input :value="modelValue.region" :options="regions" label-key="name" value-key="id" required @select="(option) => {
                onChange('region', option)
              }"/>
        </div>
      </div>
      <div class="flex-50">
        <div v-if="readOnly">
          <div class="title-gray mg-b-5">{{$t('store.district')}}</div>
          <div class="text-dark mg-b-5">{{modelValue.district?.name}}</div>
        </div>
        <div class="form-element" v-else>
          <label>{{$t('store.district')}}</label>
          <select-input :value="modelValue.district" :options="districts" label-key="name" value-key="id" required @select="(option) => {
                onChange('district', option)
              }"/>
        </div>
      </div>
    </div>

    <div class="mg-b-5 mg-t-10" v-if="hasEquity">
      <div v-if="readOnly">
        <div class="title-gray mg-b-5">{{$t('store.equity')}}</div>
        <div class="text-dark mg-b-5">{{modelValue.equity}} %</div>
      </div>
      <div class="form-element" v-else>
        <label>{{$t('store.equity')}} %</label>
        <input :value="modelValue.equity" @change="onChange('equity', $event.target.value)" type="number" class="form-control"  placeholder="25" required/>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import Select from '@/components/select/select'

export default {
  name: 'BusinessContact',
  components: {
    datepicker: Datepicker,
    selectInput: Select
  },
  props: [
    'modelValue',
    'hasTitle',
    'readOnly',
    'regions',
    'title',
    'hasEquity'
  ],
  data () {
    return {
      districts: []
    }
  },
  mounted () {
    if (!this.readOnly && this.regions?.length) {
      this.districts = [...(this.regions.find((r) => r.id === this.modelValue.region?.id)?.districts || [])]
      this.onChange('district', { ...this.modelValue.district })
    }
  },
  methods: {
    onDelete () {
      this.$emit('delete')
    },
    onChange (key, val) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        [key]: val
      })
    }
  },
  watch: {
    'modelValue.region' (newVal) {
      if (!this.readOnly) {
        this.districts = [...(newVal.districts || [])]
        this.onChange('district', null)
      }
    },
    regions (newVal, oldVal) {
      if (!this.readOnly && !oldVal?.length && newVal?.length) {
        this.districts = [...(newVal.find((r) => r.id === this.modelValue.region?.id)?.districts || [])]
        this.onChange('district', { ...this.modelValue.district })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
